<div class="flex-container">
  <div class="flex-header">
    <ng-content select="[title]"></ng-content>
    <div class="flex-header-controls">
      <div class="control-section">
        <ng-content select="[control]"></ng-content>
      </div>

      <div class="filter-section">
        <mat-form-field class="filter-section__input">
          <mat-label>Search here</mat-label>
          <input matInput #searchInput />
          <mat-icon matSuffix>search</mat-icon>
          <mat-hint>Search by serial number or tag</mat-hint>
        </mat-form-field>

        <mat-form-field class="filter-section__dropdown">
          <mat-label>Status</mat-label>
          <mat-select [(value)]="statusFilter" #statusFilterSelect (selectionChange)="updateDeviceList()">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let status of statusList" [value]="status.name">
              {{ status.value }}
            </mat-option>
          </mat-select>
          <mat-hint>Filter by status</mat-hint>
        </mat-form-field>

        <mat-form-field class="filter-section__dropdown" *ngIf="!isOtaPage">
          <mat-label>Type</mat-label>
          <mat-select [(value)]="thingTypeFilter" #thingTypeFilterSelect (selectionChange)="updateDeviceList()">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let type of thingTypeList" [value]="type.name">
              {{ type.value }}
            </mat-option>
          </mat-select>
          <mat-hint>Filter by type</mat-hint>
        </mat-form-field>

        <mat-form-field class="filter-section__dropdown" *ngIf="isOtaPage">
          <mat-label>Device version</mat-label>
          <input
            type="text"
            matInput
            [formControl]="deviceVersionControl"
            [matAutocomplete]="auto"
            (input)="onDeviceVersionInputChange()" />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onUpdateDeviceList()">
            <mat-option *ngFor="let version of filteredDeviceVersions$ | async" [value]="version">
              {{ version }}
            </mat-option>
          </mat-autocomplete>
          <mat-hint>Filter by device version</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="flex-content mat-elevation-z8">
    <div class="table-wrapper">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select" *ngIf="isOtaPage">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="toggleAllRows()"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [disabled]="!deviceList.length">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="toggleDeviceSelection(row, $event.checked)"
              [checked]="selection.isSelected(row)"
              [disabled]="checkOtaSelection(row.firmwareUpdateStatus)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="firmwareUpdateStatus" *ngIf="isOtaPage">
          <th mat-header-cell *matHeaderCellDef>Update status</th>
          <td mat-cell *matCellDef="let element">{{ element.firmwareUpdateStatus | firmwareFormatStatus }}</td>
        </ng-container>

        <ng-container matColumnDef="serialNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial Number</th>
          <td mat-cell *matCellDef="let element">{{ element.serialNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="thingType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let element">{{ element.thingType | titlecase }}</td>
        </ng-container>

        <ng-container matColumnDef="fwVersion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Version</th>
          <td mat-cell *matCellDef="let element">{{ element.fwVersion }}</td>
        </ng-container>

        <ng-container matColumnDef="tags">
          <th mat-header-cell *matHeaderCellDef>Tags</th>
          <td mat-cell *matCellDef="let element">
            <div class="text-ellipsis">{{ element.tags }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">{{ element.status | formatStatus }}</td>
        </ng-container>

        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef>Notes</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon
              [matTooltip]="element.notes"
              matTooltipTouchGestures="auto"
              #tooltip="matTooltip"
              (click)="tooltip.toggle()"
              >notes</mat-icon
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
          <td mat-cell *matCellDef="let element">{{ element.createdAt | date: 'MM/dd/yyyy HH:mm:ss' }}</td>
        </ng-container>

        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated At</th>
          <td mat-cell *matCellDef="let element">{{ element.updatedAt | date: 'MM/dd/yyyy HH:mm:ss' }}</td>
        </ng-container>

        <ng-container matColumnDef="actions" *ngIf="!isOtaPage">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onUpdateDevice(element.id)">
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="onDeleteDevice(element.id, element.serialNumber)">
                <span>Delete</span>
              </button>
              <button mat-menu-item (click)="onDownloadCertificates(element.id)">
                <span>Download certificates</span>
              </button>
              <button mat-menu-item (click)="onCreateDeviceCommands(element.serialNumber)">
                <span>Create commands</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="no-data" *ngIf="!paginator?.length">No data to display</div>
    </div>

    <mat-paginator showFirstLastButtons [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>
</div>
