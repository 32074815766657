import { Pipe, PipeTransform } from '@angular/core';
import { DeviceStatuses, STATUS_TO_VALUE } from '../../devices-management/interfaces';

@Pipe({
  name: 'formatStatus',
})
export class DeviceFormatStatusPipe implements PipeTransform {
  transform(value: DeviceStatuses) {
    return STATUS_TO_VALUE[value];
  }
}
