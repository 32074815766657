import { Pipe, PipeTransform } from '@angular/core';
import { JobExecutionStatus, OtaUpdateStatus, STATUS_TO_VALUE } from '../../firmwares/interfaces';

@Pipe({
  name: 'firmwareFormatStatus',
})
export class FirmwareFormatStatusPipe implements PipeTransform {
  transform(value: JobExecutionStatus | OtaUpdateStatus) {
    return STATUS_TO_VALUE[value];
  }
}
