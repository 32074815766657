<div class="dialog-container">
  <h1 class="title">
    {{ data.title }}
  </h1>

  <div class="btn-group" mat-dialog-actions>
    <button class="btn cancel" mat-stroked-button mat-dialog-close color="accent">
      {{ data.buttonCancel }}
    </button>
    <button class="btn" mat-flat-button color="accent" [mat-dialog-close]="true">
      {{ data.buttonConfirm }}
    </button>
  </div>
</div>
