import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, EMPTY, fromEvent, map, merge, Observable, startWith, tap } from 'rxjs';

import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTooltip } from '@angular/material/tooltip';

import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DeviceManagementService } from '../../../devices-management/services/devices-management.service';
import { DeviceListDataSource } from './device-list-data-source';
import { IDeviceData, IDevicesQueryParams } from '../../../devices-management/interfaces';
import { confirmDialogConfig } from '../../configs/confirm-dialog';
import { deviceStatusList, deviceTypeList } from '../../constants';
import { downloadFile } from '../../utils';
import { JobExecutionStatus } from '../../../firmwares/interfaces';

@UntilDestroy()
@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
})
export class DeviceListComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() isOtaPage: boolean = false;
  @Input() firmwareType: string = '';
  @Input() deviceVersions: string[] | null = [];
  @Input() isCsvFileUploaded: boolean = false;
  @Output() selectDevices: EventEmitter<string[]> = new EventEmitter();

  public displayedColumns: string[] = [
    'serialNumber',
    'thingType',
    'fwVersion',
    'tags',
    'status',
    'notes',
    'createdAt',
    'updatedAt',
  ];
  public dataSource!: DeviceListDataSource;
  public statusFilter?: string;
  public thingTypeFilter?: string;
  public thingTypeList = deviceTypeList;
  public statusList = deviceStatusList;
  public deviceList: IDeviceData[] = [];
  public deviceAvailableForUpdateList: IDeviceData[] = [];
  public selection = new SelectionModel<IDeviceData>(true, []);

  public readonly deviceVersionControl = new FormControl('', {
    nonNullable: true,
  });

  public filteredDeviceVersions$: Observable<string[]> = EMPTY;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('statusFilterSelect') statusFilterSelect!: MatSelect;
  @ViewChild('thingTypeFilterSelect') thingTypeFilterSelect!: MatSelect;
  @ViewChild(MatAutocompleteTrigger) matAutocomplete!: MatAutocompleteTrigger;
  @ViewChild(MatTooltip) tooltip!: MatTooltip;

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (this.tooltip && this.tooltip._isTooltipVisible()) {
      this.tooltip.hide();
    }
  }

  private selectedDeviceSerialNumbers: string[] = [];

  constructor(
    public readonly dialog: MatDialog,
    private readonly devicesService: DeviceManagementService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    const queryParams: IDevicesQueryParams = {
      thingType: this.firmwareType,
    };

    this.dataSource = new DeviceListDataSource(this.devicesService);
    this.dataSource.getDeviceList(queryParams);

    if (this.isOtaPage) {
      this.displayedColumns = ['select'].concat('firmwareUpdateStatus', ...this.displayedColumns);
    } else {
      this.displayedColumns = this.displayedColumns.concat(['actions']);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['deviceVersions'] && changes['deviceVersions'].currentValue && this.deviceVersionControl) {
      this.filteredDeviceVersions$ = this.deviceVersionControl.valueChanges.pipe(
        startWith(''),
        map(value => this.filterDeviceVersionList(value || ''))
      );
    }

    if (changes['isCsvFileUploaded'] && changes['isCsvFileUploaded'].currentValue) {
      this.dataSource.getDeviceList();
    }
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchInput?.nativeElement, 'keyup')
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.updateDeviceList();
        })
      )
      .subscribe();

    this.statusFilterSelect.valueChange.pipe(untilDestroyed(this)).subscribe(() => (this.paginator.pageIndex = 0));

    if (!this.isOtaPage) {
      this.thingTypeFilterSelect.valueChange.pipe(untilDestroyed(this)).subscribe(() => (this.paginator.pageIndex = 0));
    }

    this.sort.sortChange.pipe(untilDestroyed(this)).subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.updateDeviceList();
        })
      )
      .subscribe();

    this.dataSource
      .getTotalDevices()
      .pipe(untilDestroyed(this))
      .subscribe(total => {
        this.paginator.length = total;
      });

    this.dataSource
      .getDeviceListData()
      .pipe(untilDestroyed(this))
      .subscribe(devices => {
        this.deviceList = devices;
        this.deviceAvailableForUpdateList = devices.filter(
          device => !this.checkOtaSelection(device.firmwareUpdateStatus)
        );

        if (this.selectedDeviceSerialNumbers.length) {
          this.deviceList.forEach(device => {
            if (this.selectedDeviceSerialNumbers.includes(device.serialNumber)) {
              this.selection.select(device);
            }
          });
        }
      });
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.deviceAvailableForUpdateList.length;
    return numSelected === numRows;
  }

  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectedDeviceSerialNumbers = this.selectedDeviceSerialNumbers.filter(
        sn => !this.deviceList.find(d => d.serialNumber === sn)
      );

      this.getSelectedRows();
      return;
    }

    this.deviceAvailableForUpdateList.forEach(device => {
      this.selection.select(device);
      if (!this.selectedDeviceSerialNumbers.includes(device.serialNumber)) {
        this.selectedDeviceSerialNumbers.push(device.serialNumber);
      }
    });

    this.getSelectedRows();
  }

  public checkOtaSelection(status: JobExecutionStatus): boolean {
    return ['QUEUED', 'IN_PROGRESS', 'INITIATED'].includes(status);
  }

  public onUpdateDeviceList() {
    this.paginator.pageIndex = 0;
    this.updateDeviceList();
  }

  public onDeviceVersionInputChange() {
    if (!this.deviceVersionControl.value) {
      this.updateDeviceList();
      this.matAutocomplete.closePanel();
    }
  }

  public toggleDeviceSelection(device: IDeviceData, isSelected: boolean): void {
    if (isSelected) {
      this.selectedDeviceSerialNumbers.push(device.serialNumber);
    } else {
      this.selectedDeviceSerialNumbers = this.selectedDeviceSerialNumbers.filter(s => s !== device.serialNumber);
    }

    this.selection.toggle(device);
    this.getSelectedRows();
  }

  public getSelectedRows() {
    this.selectDevices.emit(this.selectedDeviceSerialNumbers);
  }

  public updateDeviceList(): void {
    const queryParams: IDevicesQueryParams = {
      page: this.paginator.pageIndex + 1,
      perPage: this.paginator.pageSize,
      search: this.searchInput.nativeElement.value,
      thingType: this.thingTypeFilter || this.firmwareType,
      status: this.statusFilter,
      fwVersion: this.deviceVersionControl.value,
      sortBy: this.sort.active,
      sortDirection: this.sort.direction.toUpperCase(),
    };

    this.dataSource.getDeviceList(queryParams);

    if (this.isOtaPage) {
      this.selection.clear();
    }
  }

  public onUpdateDevice(deviceId: number): void {
    this.router.navigateByUrl(`devices/edit/${deviceId}`);
  }

  public onCreateDeviceCommands(serialNumber: string): void {
    this.router.navigateByUrl(`devices/commands/${serialNumber}`);
  }

  public onDeleteDevice(deviceId: number, deviceNumber: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      ...confirmDialogConfig,
      panelClass: 'delete-device-container',
      data: {
        title: `Are you sure you want to remove device ${deviceNumber}?`,
        buttonCancel: 'No',
        buttonConfirm: 'Yes',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result) {
          this.devicesService
            .deleteDevice(deviceId)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              this.paginator.pageIndex = 0;
              this.updateDeviceList();
            });
        }
      });
  }

  public onDownloadCertificates(deviceId: number) {
    this.devicesService
      .downloadCertificatesById(deviceId)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        const defaultFileName = 'colibri.zip';

        if (response.body) {
          downloadFile(response, defaultFileName);
        }
      });
  }

  private filterDeviceVersionList(value: string): string[] {
    const filterValue = value.toLowerCase();

    if (this.deviceVersions) {
      return this.deviceVersions.filter(option => option.toLowerCase().includes(filterValue));
    }

    return [];
  }
}
