import { FirmwareStatuses } from '../../firmwares/interfaces';
import { DeviceStatuses, DeviceTypes } from '../../devices-management/interfaces';

type DeviceType = {
  name: DeviceTypes;
  value: string;
};

type DeviceStatus = {
  name: DeviceStatuses;
  value: string;
};

type FirmwareStatus = {
  name: FirmwareStatuses;
  value: string;
};

export const deviceTypeList: DeviceType[] = [
  {
    name: 'telemetry',
    value: 'Telemetry',
  },
  {
    name: 'charger',
    value: 'Charger',
  },
];

export const deviceStatusList: DeviceStatus[] = [
  {
    name: 'in_production',
    value: 'In production',
  },
  {
    name: 'ready_for_shipping',
    value: 'Ready for shipping',
  },
  {
    name: 'on_the_way',
    value: 'On the way',
  },
  {
    name: 'installed',
    value: 'Installed',
  },
];

export const firmwareStatusList: FirmwareStatus[] = [
  {
    name: 'NEW',
    value: 'New',
  },
  {
    name: 'PUBLISHED',
    value: 'Published',
  },
];
