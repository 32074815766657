import { JobExecutionStatus } from '../../firmwares/interfaces';

export type DeviceStatuses = 'in_production' | 'ready_for_shipping' | 'on_the_way' | 'installed';
export type DeviceTypes = 'charger' | 'telemetry';

export const STATUS_TO_VALUE: Record<DeviceStatuses, string> = {
  in_production: 'In production',
  ready_for_shipping: 'Ready for shipping',
  on_the_way: 'On the way',
  installed: 'Installed',
};

export interface IDeviceList {
  data: IDeviceData[];
  meta: {
    pagination: {
      page: number;
      perPage: number;
      total: number;
    };
  };
}

export interface IDeviceData {
  id: number;
  serialNumber: string;
  thingType: DeviceTypes;
  certificateId: string;
  fwVersion: string;
  notes: string;
  tags: string;
  status: DeviceStatuses;
  firmwareUpdateStatus: JobExecutionStatus;
  createdAt: string;
  updatedAt: string;
}

export interface IFormDevice {
  serialNumber: string;
  thingType: DeviceTypes | '';
  status: DeviceStatuses | '';
  notes?: string;
  tags?: string;
}

export interface IDeviceCommands {
  type: string;
  commands: string[];
}

export interface ICommandResponse {
  responseBody: string;
  createdAt: string;
}
