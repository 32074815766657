import { IFirmwareData } from './firmwares-management.interface';

export type JobExecutionStatus =
  | 'CANCELED'
  | 'FAILED'
  | 'IN_PROGRESS'
  | 'INITIATED'
  | 'QUEUED'
  | 'REJECTED'
  | 'REMOVED'
  | 'SUCCEEDED'
  | 'TIMED_OUT';
export type OtaUpdateStatus = 'UPDATE_START' | 'UPDATE_IN_PROGRESS' | 'UPDATE_SUCCESSFUL' | 'UPDATE_FAILED';

export const STATUS_TO_VALUE: Record<JobExecutionStatus | OtaUpdateStatus, string> = {
  CANCELED: 'Canceled',
  FAILED: 'Failed',
  IN_PROGRESS: 'In progress',
  INITIATED: 'Initiated',
  QUEUED: 'Queued',
  REJECTED: 'Rejected',
  REMOVED: 'Removed',
  SUCCEEDED: 'Succeeded',
  TIMED_OUT: 'Timed out',
  UPDATE_START: 'Update start',
  UPDATE_IN_PROGRESS: 'Update in progress',
  UPDATE_SUCCESSFUL: 'Update successful',
  UPDATE_FAILED: 'Update failed',
};

export interface IOtaUpdates {
  id: number;
  firmwareDataId: number;
  awsJobId: string;
  status: OtaUpdateStatus;
  errorInfo: {
    message: string;
  };
  createdAt: string;
  updatedAt: string;
  firmwareData: IFirmwareData;
  jobExecutionLogs: IJobExecutionLogs[];
}

export interface IJobExecutionLogs {
  id: number;
  awsJobId: string;
  thingName: string;
  status: JobExecutionStatus;
  statusDetails: {
    reason: string;
  };
  createdAt: string;
  updatedAt: string;
}
