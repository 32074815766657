import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { confirmDialogConfig } from '../../configs/confirm-dialog';
import { AuthService } from '../../services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  isDesktop: boolean = true;
  token: string = '';

  constructor(
    public readonly auth: Auth0Service,
    public readonly dialog: MatDialog,
    private readonly authService: AuthService,
    private readonly _breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this._breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.isDesktop = result.matches;
      });
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      ...confirmDialogConfig,
      panelClass: 'log-out-container',
      data: {
        title: 'Are you sure you want to log out?',
        buttonCancel: 'No',
        buttonConfirm: 'Yes',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result) {
          this.authService.logout();
        }
      });
  }
}
