import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LoaderService } from '../../services/loader.service';

@UntilDestroy()
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  public isLoading: boolean = false;

  constructor(private readonly service: LoaderService) {}

  ngOnInit(): void {
    this.service.isLoading$.pipe(untilDestroyed(this)).subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }
}
