import { BehaviorSubject, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DeviceManagementService } from '../../../devices-management/services/devices-management.service';
import { IDeviceData, IDevicesQueryParams } from '../../../devices-management/interfaces';

@UntilDestroy()
export class DeviceListDataSource implements DataSource<IDeviceData> {
  private readonly devicesSubject = new BehaviorSubject<IDeviceData[]>([]);
  private readonly totalDevicesSubject = new BehaviorSubject<number>(0);

  constructor(private readonly devicesService: DeviceManagementService) {}

  connect(): Observable<IDeviceData[]> {
    return this.devicesSubject.asObservable();
  }

  disconnect(): void {
    this.devicesSubject.complete();
  }

  getDeviceList(queryParams?: IDevicesQueryParams) {
    this.devicesService
      .getDevices(queryParams)
      .pipe(untilDestroyed(this))
      .subscribe(devices => {
        this.devicesSubject.next(devices.data);
        this.totalDevicesSubject.next(devices.meta.pagination.total);
      });
  }

  getTotalDevices(): Observable<number> {
    return this.totalDevicesSubject.asObservable();
  }

  getDeviceListData(): Observable<IDeviceData[]> {
    return this.devicesSubject.asObservable();
  }
}
