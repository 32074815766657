import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, Observable, tap, throwError } from 'rxjs';

import { LoaderService } from '../services/loader.service';
import { SnackbarService } from '../services/snackbar.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private count = 0;

  constructor(
    private readonly loaderService: LoaderService,
    private readonly snackbarService: SnackbarService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loaderService.showLoader();

    return next.handle(request).pipe(
      tap(event => {
        if (request.method == 'POST' || request.method == 'PUT' || request.method == 'DELETE') {
          if (event instanceof HttpResponse && (event.status === 200 || event.status === 201)) {
            this.snackbarService.showSuccess('Operation successful!');
          }
        }
      }),
      finalize(() => {
        this.loaderService.hideLoader();
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error?.message || 'An error occurred!';

        this.snackbarService.showError(errorMessage);

        return throwError(() => error);
      })
    );
  }
}
