import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../shared/services/api.service';
import { IFormDevice, IDeviceList, IDeviceData, IDevicesQueryParams, IDeviceCommands } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class DeviceManagementService extends ApiService {
  getDevices(queryParams?: IDevicesQueryParams): Observable<IDeviceList> {
    if (queryParams) {
      if (queryParams.sortDirection === '') {
        delete queryParams.sortBy;
      }

      let params = new HttpParams();

      Object.entries(queryParams).forEach(([key, value]) => {
        if (value) {
          params = params.set(key, value.toString());
        }
      });

      return this.httpClient.get<IDeviceList>(`${this.apiUrl}/devices`, { params });
    } else {
      return this.httpClient.get<IDeviceList>(`${this.apiUrl}/devices`);
    }
  }

  getDeviceById(deviceId: number): Observable<IDeviceData> {
    return this.httpClient.get<IDeviceData>(`${this.apiUrl}/devices/${deviceId}`);
  }

  getDeviceVersions(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.apiUrl}/devices/versions`);
  }

  createDevice(data: Partial<IFormDevice>): Observable<IDeviceData> {
    return this.httpClient.post<IDeviceData>(`${this.apiUrl}/devices`, data);
  }

  updateDevice(deviceId: number, data: Partial<IFormDevice>): Observable<IDeviceData> {
    return this.httpClient.put<IDeviceData>(`${this.apiUrl}/devices/${deviceId}`, data);
  }

  deleteDevice(deviceId: number): Observable<IDeviceData> {
    return this.httpClient.delete<IDeviceData>(`${this.apiUrl}/devices/${deviceId}`);
  }

  uploadCsvFile(file: FormData): Observable<IDeviceData[]> {
    return this.httpClient.post<IDeviceData[]>(`${this.apiUrl}/devices/upload`, file);
  }

  downloadCertificatesById(deviceId: number) {
    return this.httpClient.get(`${this.apiUrl}/devices/${deviceId}/certificates`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  createDeviceCommands(serialNumber: string, data: IDeviceCommands) {
    return this.httpClient.post(`${this.apiUrl}/devices/${serialNumber}/commands`, data);
  }
}
