import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private snackbarConfig: MatSnackBarConfig = {
    horizontalPosition: 'right',
    verticalPosition: 'bottom',
    duration: 3000,
  };

  constructor(private readonly snackBar: MatSnackBar) {}

  showSuccess(message: string): void {
    this.snackBar.openFromComponent(SnackBarComponent, {
      ...this.snackbarConfig,
      data: message,
      panelClass: ['success-snackbar'],
    });
  }

  showError(message: string): void {
    this.snackBar.openFromComponent(SnackBarComponent, {
      ...this.snackbarConfig,
      data: message,
      panelClass: ['error-snackbar'],
    });
  }
}
