import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const baseValidatorFunction = (regexp: RegExp, patternName: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    return !regexp.test(value) ? { [patternName]: true } : null;
  };
};

export const serialNumberFormatValidator = () => {
  const serialNumberFormat = /^[0-9A-Fa-f]{1,6}$/;

  return baseValidatorFunction(serialNumberFormat, 'serialNumberFormat');
};

export const firmwareVersionFormatValidator = () => {
  const firmwareVersionFormat = /^\d{1,4}\.\d{1,4}\.\d{1,4}$/;

  return baseValidatorFunction(firmwareVersionFormat, 'firmwareVersionFormat');
};
