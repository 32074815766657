<main>
  <mat-toolbar class="header" color="primary">
    <div class="logo">
      <button *ngIf="!isDesktop" mat-icon-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <img src="../assets/images/logo_white.png" />
    </div>
  </mat-toolbar>

  <mat-drawer-container class="container">
    <mat-drawer mode="side" class="sidebar" #drawer [mode]="isDesktop ? 'side' : 'over'" [opened]="!!isDesktop">
      <div class="user-container">
        <div class="user-info">
          <p class="user-name">Admin</p>
          <p class="user-email" *ngIf="auth.user$ | async as user">{{ user.email }}</p>
        </div>
        <div class="user-toggle">
          <div>
            <button mat-icon-button [matMenuTriggerFor]="matMenu">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #matMenu>
              <button mat-menu-item (click)="openDialog()">
                <span>Log Out</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <mat-nav-list class="list">
        <a
          mat-list-item
          class="sidebar-link"
          routerLinkActive="active"
          routerLink="/devices"
          (click)="!isDesktop ? drawer.toggle() : null">
          Devices Management
        </a>
        <a
          mat-list-item
          class="sidebar-link"
          routerLinkActive="active"
          routerLink="/firmwares"
          (click)="!isDesktop ? drawer.toggle() : null">
          OTA Updates
        </a>
      </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content class="content">
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
  <app-loader></app-loader>
</main>
