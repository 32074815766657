import { HttpHeaders, HttpResponse } from '@angular/common/http';

export const downloadFile = (response: HttpResponse<Blob>, filename: string) => {
  const url = window.URL.createObjectURL(response.body!);
  const anchor = document.createElement('a');

  anchor.href = url;
  anchor.download = getFileName(response.headers, filename);
  anchor.click();
  window.URL.revokeObjectURL(url);
};

export const getFileName = (headers: HttpHeaders, filename: string) => {
  const contentDisposition = headers.get('content-disposition');

  if (contentDisposition) {
    const matches = /filename="([^"]+)"/.exec(contentDisposition);

    if (matches?.length === 2) {
      return matches[1];
    }
  }

  return filename;
};
