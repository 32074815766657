import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DeviceListComponent } from './components/device-list/device-list.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { UploadFileDialogComponent } from './components/upload-file-dialog/upload-file-dialog.component';

import { DeviceFormatStatusPipe } from './pipes/device-format-status.pipe';
import { FirmwareFormatStatusPipe } from './pipes/firmware-format-status.pipe';
import { InputTrimDirective } from './directives/input-trim.directive';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    DeviceListComponent,
    LoaderComponent,
    MainLayoutComponent,
    SnackBarComponent,
    UploadFileDialogComponent,
    DeviceFormatStatusPipe,
    FirmwareFormatStatusPipe,
    InputTrimDirective,
  ],
  exports: [
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    ConfirmDialogComponent,
    DeviceListComponent,
    LoaderComponent,
    SnackBarComponent,
    UploadFileDialogComponent,
    DeviceFormatStatusPipe,
    FirmwareFormatStatusPipe,
    InputTrimDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  providers: [],
})
export class SharedModule {}
