import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmDialogData } from '../../interfaces';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
})
export class UploadFileDialogComponent {
  public fileName: string = '';
  public formData: FormData = new FormData();
  public inputFileValid: boolean = false;

  @ViewChild('fileUpload') fileUploadRef!: ElementRef;

  constructor(
    public readonly dialogRef: MatDialogRef<UploadFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: ConfirmDialogData
  ) {}

  public onFileSelected(event: Event): void {
    const csvFile: File = (event.target as HTMLInputElement).files![0];

    if (csvFile) {
      this.fileName = csvFile.name;
      this.formData.append('file', csvFile);
      this.inputFileValid = !!this.fileName;
    }
  }

  public onUploadFile(): void {
    this.dialogRef.close(this.formData);
  }

  public resetFile(): void {
    this.fileUploadRef.nativeElement.value = '';
    this.fileName = '';
  }
}
