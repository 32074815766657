<div class="dialog-container">
  <h1 class="title">
    {{ data.title }}
  </h1>

  <input type="file" class="file-input" accept=".csv" (change)="onFileSelected($event)" #fileUpload />
  <div class="file-upload">
    <button mat-mini-fab color="accent" class="upload-btn" (click)="fileUpload.click()">
      <mat-icon>attach_file</mat-icon>
    </button>
    <span class="file-name">
      <span class="inner-wrapper">{{ fileName || 'No file uploaded yet' }}</span>
    </span>
    <button mat-icon-button color="accent" type="button" *ngIf="fileName" (click)="resetFile()">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>

  <div class="btn-group" mat-dialog-actions>
    <button class="btn cancel" mat-stroked-button mat-dialog-close color="accent">
      {{ data.buttonCancel }}
    </button>
    <button
      class="btn"
      mat-flat-button
      color="accent"
      [disabled]="!inputFileValid || !fileName"
      (click)="onUploadFile()">
      {{ data.buttonConfirm }}
    </button>
  </div>
</div>
