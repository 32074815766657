import { IOtaUpdates } from './firmware-logs.interface';

export type FirmwareStatuses = 'NEW' | 'PUBLISHED';
export type FirmwareTypes = 'BMS' | 'TM';

export interface IFirmwareList {
  data: IFirmwareData[];
  meta: {
    pagination: {
      page: number;
      perPage: number;
      total: number;
    };
  };
}

export interface IFirmwareData {
  id: number;
  name: string;
  version: string;
  type: FirmwareTypes;
  filename: string;
  hwCompatibility: string;
  featureImplemented: string;
  issuesFixed: string;
  knownIssues: string;
  comments: string;
  status: FirmwareStatuses;
  releasedAt: string;
  createdAt: string;
  updatedAt: string;
  otaUpdate: IOtaUpdates[];
}

export interface IFormFirmware {
  name: string;
  version: string;
  type: FirmwareTypes | '';
  hwCompatibility: string;
  featureImplemented: string;
  issuesFixed: string;
  knownIssues: string;
  comments: string;
}
