export interface IDevicesQueryParams {
  page?: number;
  perPage?: number;
  search?: string;
  thingType?: string;
  status?: string;
  fwVersion?: string;
  sortBy?: string;
  sortDirection?: string;
}
